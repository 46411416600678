import { User } from '@/app/types/api/resources/auth'
import { UserState } from '@/app/types/store/store'

export default {
  namespaced: true,

  state: (): UserState => ({
    user: null,
    isEmailVerified: null,
  }),

  mutations: {
    setUser(state: UserState, user: User) {
      state.user = user
    },
    setEmailVerification(state: UserState, isVerified: boolean) {
      state.isEmailVerified = isVerified
    },
  },

  actions: {},
  getters: {},
}
