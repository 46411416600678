import { User } from '@/app/types/api/resources/auth'
import { ServiceState } from '@/app/types/store/service'

export interface UserState {
  user: User | null
  isEmailVerified: boolean | null
}

export interface RootState {
  auth: UserState
  service: ServiceState
}

export enum StoreCommit {
  SetUser = 'auth/setUser',
  SetEmailVerification = 'auth/setEmailVerification',
  ChangeServerStatus = 'service/changeServerStatus',
}
