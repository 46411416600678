import { AxiosResponse } from 'axios'

import { DictionaryResponse } from '@/app/types/api/config'
import {
  AuthApi,
  AuthFormData,
  AuthResponse,
  EmailApi,
  EmailVerificationResponse,
  RecoveryData,
  SignupFormData,
  UserResponse,
  UsersApi,
} from '@/app/types/api/resources/auth'

import client from '@/app/api/client'

export default {
  fetchUser(): Promise<AxiosResponse<UserResponse>> {
    return client.get(UsersApi.FetchUser)
  },

  login(data: AuthFormData): Promise<AxiosResponse<AuthResponse>> {
    return client.post(AuthApi.Login, data)
  },

  logout(): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.Logout)
  },

  signup(data: SignupFormData): Promise<AxiosResponse<AuthResponse>> {
    return client.post(AuthApi.Signup, data)
  },

  sendRecoveryLink(data: { email: string }): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.ForgotPassword, data)
  },

  resendLink(): Promise<AxiosResponse<void>> {
    return client.post(EmailApi.Resend)
  },

  checkEmailVerification(): Promise<
    DictionaryResponse<EmailVerificationResponse>
  > {
    return client.get(EmailApi.CheckEmailVerification)
  },

  checkRecoveryToken(
    data: Partial<RecoveryData>,
  ): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.CheckResetToken, data)
  },

  resetPassword(data: RecoveryData): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.ResetPassword, data)
  },

  verifyEmail(query: string): Promise<AxiosResponse<void>> {
    return client.post(`api${query}`)
  },
}
