import { createRouter, createWebHistory } from 'vue-router'

import { RouteName } from '@/app/types/app/routes'

import cookies from '@/plugins/cookies'
import Store from '@/store'
import AuthStoreService from '@/store/services/AuthStoreService'

import ClientResponseError from '@/app/ClientResponseError'
import env from '@/app/env'

import { resolveServicePageRoute } from '@/router/resources/routerResources'
import routes from '@/router/resources/routes'

import serverStatusGlobal from './middleware/global/serverStatus.global'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from) => {
  const token = cookies.get(env.cookieName)
  const routeName = to.name as RouteName
  const isVerifyRoute = [
    RouteName.EmailVerify,
    RouteName.EmailVerifyFailed,
    RouteName.EmailVerifyToken,
  ].includes(routeName)

  // catch routes
  const isRecoveryRouteCatch =
    routeName.includes(RouteName.PasswordRecovery) &&
    routeName !== RouteName.PasswordRecovery &&
    !from.name
  const isVerifyRouteCatch = isVerifyRoute && !token

  if (isRecoveryRouteCatch || isVerifyRouteCatch) {
    return { name: RouteName.Login }
  }

  // check user data
  if (token) {
    try {
      await serverStatusGlobal(to, from)
    } catch (error) {
      return resolveServicePageRoute(
        error as Error | ClientResponseError,
        to.name as RouteName,
      )
    }

    const { state } = Store

    if (state.auth.isEmailVerified === null) {
      const authService = new AuthStoreService()

      await authService.checkEmailVerification()
    }

    if (state.auth.isEmailVerified) {
      window.location.href = env.dashboardUrl
    } else if (!isVerifyRoute) {
      // to avoid the loop
      return { name: RouteName.EmailVerify }
    }
  }
})

export default router
